import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { SharedModule } from "./theme/shared/shared.module";
import { AppComponent } from './app.component';
import {NgbDropdownModule, NgbTooltipModule, NgbNavModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { OAuthModule } from 'angular-oauth2-oidc';
import { CommonBPConfig } from "./globalBpConfig";
import { WizardBudgetPlannerService } from "./WizardBudgetPlannerService";
import { MatStepperModule } from "@angular/material/stepper";
import { DemoMaterialModule } from "./material-module";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BudgetPlannerServiceBase} from "@expertasolutions/budgetplanner-ui-common";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    MatStepperModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbNavModule,
    DemoMaterialModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    OAuthModule.forRoot({
      resourceServer:
        {
          allowedUrls: [ window.location.origin + '/api'],
          sendAccessToken: true
        }
    })
  ],
  providers: [
    CommonBPConfig, WizardBudgetPlannerService,
    {
      provide: BudgetPlannerServiceBase, useClass: WizardBudgetPlannerService
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' }
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
