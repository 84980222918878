import {Injectable} from "@angular/core";

@Injectable()
export class CommonBPConfig {

  constructor() { }

  /*
  style = 'material';
  timeout = 500;
  position: SnotifyPosition = SnotifyPosition.centerTop;
  progressBar = false;
  closeClick = true;
  newTop = true;
  filterDuplicates = false;
  backdrop = -1;
  dockMax = 8;
  blockMax = 6;
  pauseHover = true;
  titleMaxLength = 25;
  bodyMaxLength = 80;

  getConfig(): SnotifyToastConfig {
    this.snotifyService.setDefaults({
      global: {
        newOnTop: this.newTop,
        maxAtPosition: this.blockMax,
        maxOnScreen: this.dockMax,
        // @ts-ignore
        filterDuplicates: this.filterDuplicates
      }
    });
    return {
      bodyMaxLength: this.bodyMaxLength,
      titleMaxLength: this.titleMaxLength,
      backdrop: this.backdrop,
      position: this.position,
      timeout: this.timeout,
      showProgressBar: this.progressBar,
      closeOnClick: this.closeClick,
      pauseOnHover: this.pauseHover
    };
  }
  */

  toastSuccess(msg: string) {
    //this.snotifyService.success(msg, 'Success', this.getConfig());
    console.log(msg);
    alert(msg);
  }
}
